import React, { useEffect, useState } from 'react';
import Breadcumb from '../../Layouts/Breadcumb'
import axios from 'axios';
import { useParams } from 'react-router-dom';
export default function BlogDetails() {
    const { blogId } = useParams();
    const [blogDetail, setBlogDetail] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        const fetchBlogDetail = async () => {
            try {
                const response = await axios.get(`https://thecognize.com/admin/api/blogDetail/${blogId}`);
                // Assuming response.data contains the blog post details directly
                setBlogDetail(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error.response ? error.response.data : error.message);
                setError('Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };

        fetchBlogDetail();
    }, [blogId]);
   
    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p className="text-center">{error}</p>;
    }
  return (
    <>
    <Breadcumb pagetitle="Blog Details"/>
    <section className='py-5'>
        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-lg-10'>
                    <div className='blog-box p-3 border rounded-4'>
                        <div className='blocimg'>
                          <a href='javascript:void(0)'>
                            <img src={blogDetail.image} alt={blogDetail.title}  onError={(e) => e.currentTarget.src = '/assets/images/blog1.jpg'}/>
                            </a>
                        </div>
                        <div className='blogcontent'>
                            <h2 className='h4 mt-3 mb-2'><a href='javascript:void(0)' className='text-dark'>{blogDetail.title}</a></h2>
                            <div className='d-flex gap-3 dashoption mb-3'>
                                <div><a href='javascript:void(0)' className='text-muted'><i class="far fa-user"></i> Admin</a></div>
                                <div><a href='javascript:void(0)' className='text-muted'><i class="far fa-calendar-alt"></i> {blogDetail.created}</a></div>
                            </div>    
                            <p>{blogDetail.description}</p>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
